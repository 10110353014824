import React, { useState, useEffect } from 'react';
import { TextField } from '@material-ui/core';
import { InputCep } from './MaskedInput';

export function InputViaCep({ value, name, onChange, onSuccess, onLoading }) {
  const [valid, setValid] = useState(true);
  const [error, setError] = useState(false);

  useEffect(() => {
    const formattedCep = value.replace(/\D/g, '');

    if (formattedCep.length !== 8) {
      setValid(true);
      return;
    }

    onLoading(true);

    fetch(`https://viacep.com.br/ws/${formattedCep}/json/`)
      .then((res) => res.json())
      .then((data) => {
        if (data.erro) {
          setError(true);
          setValid(false);
        } else {
          onSuccess(data);
          setError(false);
          setValid(true);
        }
      })
      .catch((error) => {
        console.error('Error', error);
        setError(true);
        setValid(false);
      })
      .finally(() => onLoading(false));
  }, [value]);

  return (
    <TextField
      fullWidth
      label="CEP"
      name={name}
      value={value}
      error={!valid}
      helperText={error ? "CEP não encontrado" : !valid && "CEP incorreto"}
      onChange={onChange}
      InputProps={{ inputComponent: InputCep }}
    />
  );
}
import React from 'react';
import Routes from './routes';
import { SnackbarProvider } from 'notistack';


function App() {

  return (
    <div className="App">
   
        <SnackbarProvider 
          maxSnack={3}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}>
          <Routes />
        </SnackbarProvider>
     
    </div> 
  )
}


export default App;

import React, { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';

function PasswordModal({ open, onClose, onPasswordSubmit, destination }) {
    const [password, setPassword] = useState('');
    const [isIncorrectPassword, setIsIncorrectPassword] = useState(false);

    useEffect(() => {
        setIsIncorrectPassword(false);
    }, [open]);

    const handlePasswordChange = (event) => {
        setPassword(event.target.value);
        setIsIncorrectPassword(false);
    };

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            handleSubmit();
        }

        if (event.key === 'Escape') {
            onClose();
        }
    };

    const handleSubmit = () => {
        const isPasswordCorrect = password === 'balanca123'
        if (isPasswordCorrect) {
            onPasswordSubmit(destination);
        } else {
            setIsIncorrectPassword(true);
        }
    };

    return (
        <Dialog
            open={open}
            onClose={onClose}
            onKeyPress={handleKeyPress}
            style={{ textAlign: 'center' }}
            maxWidth='xs'>

            <DialogContentText style={{ color: 'black', textAlign: 'center', fontSize: '20px', fontWeight: 'bold', marginBottom: '10px', marginTop: '20px' }} id='error-dialog-title'>Digite a senha</DialogContentText>
            <DialogContent>
                <DialogContentText style={{ color: 'black', marginBottom: '10px' }}>
                    Por favor, insira a senha para continuar.
                </DialogContentText>
                <TextField
                    autoFocus
                    margin="dense"
                    label="Senha"
                    type="password"
                    fullWidth
                    value={password}
                    onChange={handlePasswordChange}
                    onKeyPress={handleKeyPress}
                    error={isIncorrectPassword}
                    helperText={isIncorrectPassword ? 'Senha incorreta' : ''}
                />
            </DialogContent>
            <DialogActions style={{ justifyContent: 'center', alignItems: 'center'}}>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop:'15px', marginBottom:'8px' }}>
                    <Button variant='outlined' color='primary' style={{ borderRadius: '1em', marginRight: '10px', width:'118px' }} onClick={onClose}>Cancelar</Button>
                    <Button variant='contained' color='primary' style={{ borderRadius: '1em', marginLeft: '10px' }} onClick={handleSubmit}>Confirmar</Button>
                </div>
            </DialogActions>

        </Dialog>
    );
}

export default PasswordModal;
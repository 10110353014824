export const valoresPacotesTreinamentos = {
    tipo: 'pacote-treinamento',
    tituloPrincipal: "Pacote de Treinamentos",
    pacotes: [
        {
            id: "EAD_SETUP",
            descricao: "EAD & Setup",
            horas: 2,
            valor: 499.90,
            valorHora: 250.00,
            bloqueado: true,
            selecionado: true,
            customizavel: false,
        },
        {
            id: "BASICO",
            descricao: "Básico",
            horas: 5,
            valor: 1000.00,
            valorHora: 250.00,
            bloqueado: false,
            selecionado: false,
            customizavel: false,
        },
        {
            id: "COMPLETO",
            descricao: "Completo",
            horas: 10,
            valor: 1500.00,
            valorHora: 150.00,
            bloqueado: false,
            selecionado: true,
            customizavel: false,
        },
        {
            id: "CUSTOMIZADO",
            descricao: "Horas adicionais",
            horas: 0,
            valor: 150.00,
            bloqueado: false,
            selecionado: false,
            customizavel: true,
        },
    ]
}
import React from 'react';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Link from '@material-ui/core/Link';
import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles((theme) => ({
    footer: {
      padding: theme.spacing(8, 0),
      marginTop: 'auto',
    },
  }));

function Copyright() {
    return (
      <Typography variant="body2" >
        {'Copyright © '}
        <Link color="inherit" href="http://parseint.com.br">
          Parseint
        </Link>{' '}
        {new Date().getFullYear()}
        {'.'}
      </Typography>
    );
  }

  export default function Footer() {

    const classes = useStyles();

    return (
        <footer className={classes.footer}>
            <Container maxWidth="lg">
                <Copyright />
            </Container>
        </footer>
    )
  }

  
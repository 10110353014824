import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { Box, Checkbox, Container, FormControl, FormControlLabel, Grid, InputAdornment, InputLabel, MenuItem, OutlinedInput, Select, TextField, Typography } from '@material-ui/core';
import numero from 'numero-por-extenso';
import { format, parse } from 'date-fns';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  margin: {
    margin: theme.spacing(1),
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
}));


export default function Contrato() {
  const classes = useStyles();
  const [campos, setCampos] = useState({ periodoContrato: 12, diaVencimento: 10, dataContrato: format(new Date(), 'dd/MM/yyyy'), dataContratoOriginal: '' });

  const [isChecked, setChecked] = useState(false);

  const handleCheckboxChange = (event) => {
    setChecked(event.target.checked);
  };

  const handlePaste = (event) => {
    const clipboardData = event.clipboardData || window.clipboardData;
    const pastedText = clipboardData.getData('text');

    // Verificar se o texto colado é uma data válida
    if (/^\d{2}\/\d{2}\/\d{4}$/.test(pastedText)) {
      const date = parse(pastedText, 'dd/MM/yyyy', new Date())
      const formatedDate = format(date, 'yyyy-MM-dd')
      setCampos(state => ({ ...state, dataContratoOriginal: formatedDate }))
    } else {
      // Impedir a colagem se o texto não for uma data válida
      event.preventDefault();
    }
  };

  const handleSaveToPC = (jsonData, filename) => {
    const fileData = JSON.stringify(jsonData);
    const blob = new Blob([fileData], { type: "text/plain" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.download = `${filename}.json`;
    link.href = url;
    link.click();
  }


  
  function onclickSave() {
    const data = format(new Date(), 'dd/MM/yyyy')

    const camposCopy = { ...campos }

    if (isChecked) {
      const dataContratoOriginalDate = parse(camposCopy.dataContratoOriginal, 'yyyy-MM-dd', new Date())

      camposCopy.dataContratoOriginal = format(dataContratoOriginalDate, 'dd/MM/yyyy')
    }
    handleSaveToPC(camposCopy, `${camposCopy.razaoSocial}_${data}`)
  }

  function handleChange(event) {
    const nomeCampo = event.target.name
    const valorCampo = event.target.value

    if (event.target.type === 'number') {
      setCampos({ ...campos, [nomeCampo]: valorCampo, [nomeCampo + 'Extenso']: numero.porExtenso(valorCampo, numero.estilo.monetario) })
    } else if (event.target.type === 'tel') {
      setCampos({ ...campos, [nomeCampo]: valorCampo, [nomeCampo + 'Extenso']: numero.porExtenso(valorCampo, numero.estilo.normal) + ' horas' })
    } else {
      setCampos({ ...campos, [nomeCampo]: valorCampo })
    }
  }

  return (
    <Container maxWidth='md'>

      <Typography component="h1" variant="h2" align="center" color="textPrimary" gutterBottom>
        Contrato
      </Typography>
      <FormControlLabel
        control={
          <Checkbox
            checked={isChecked}
            onChange={handleCheckboxChange}
            name="checkboxContratoOriginal"
          />
        }
        label="Adendo Contratual"
      />

      {isChecked && (
        <TextField
          type="date"
          label="Data do Contrato Original"
          name="dataContratoOriginal"
          value={campos.dataContratoOriginal}
          onChange={handleChange}
          onPaste={handlePaste}
          InputLabelProps={{
            shrink: true,
          }}
          style={{ width: '200px' }}
        />

      )}


      <Box paddingTop={10}>
        <Grid container spacing={3}>

          <Grid item xs={12}>
            <TextField fullWidth onChange={handleChange}
              name='sistemaContratado' label="Sistema Contratado"
              variant="outlined" select>
              <MenuItem value="SISCHEF">SISTEMA SISCHEF</MenuItem>
              <MenuItem value="SISAGIL">SISTEMA SISAGIL</MenuItem>
              <MenuItem value="QRPEDIR">SISTEMA QRPEDIR</MenuItem>
            </TextField>
          </Grid>


          <Grid item xs={12}>
            <TextField fullWidth onChange={handleChange} name='razaoSocial' label="Razão social" variant="outlined" />
          </Grid>

          <Grid item xs={6}>
            <TextField fullWidth onChange={handleChange} name='cnpj' label="CNPJ" variant="outlined" />
          </Grid>

          <Grid item xs={6}>
            <TextField fullWidth onChange={handleChange} name='inscricaoEstadual' label="Inscricao Estadual" variant="outlined" />
          </Grid>

          <Grid item xs={12}>
            <TextField fullWidth onChange={handleChange} name='enderecoEmpresa' label="Endereço completo da empresa" variant="outlined" />
          </Grid>

          <Grid item xs={12}>
            <TextField fullWidth onChange={handleChange} name='representadaPor' label="Representada Por" variant="outlined" />
          </Grid>

          <Grid item xs={6}>
            <TextField fullWidth onChange={handleChange} name='cpf' label="CPF" variant="outlined" />
          </Grid>

          <Grid item xs={6}>
            <TextField fullWidth onChange={handleChange} name='rg' label="RG" variant="outlined" />
          </Grid>

          <Grid item xs={12}>
            <TextField fullWidth onChange={handleChange} name='enderecoRepresentante' label="Endereço" variant="outlined" />
          </Grid>

          <Grid item xs={12}>
            <TextField fullWidth onChange={handleChange} name='email' label="E-mail" variant="outlined" />
          </Grid>

          <Grid item xs={4}>
            <FormControl fullWidth variant="outlined" className={classes.formControl}>
              <InputLabel shrink id="diaVencimento">Dia do vencimento</InputLabel>
              <Select
                fullWidth
                defaultValue='10'
                labelId="diaVencimento"
                name="diaVencimento"
                onChange={handleChange}
              >
                <MenuItem fullWidth value='5'>5</MenuItem>
                <MenuItem value='10'>10</MenuItem>
                <MenuItem value='15'>15</MenuItem>
                <MenuItem value='20'>20</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={4}>

            <FormControl fullWidth variant="outlined" className={classes.formControl}>
              <InputLabel shrink id="mesCorrecao">Mês correção</InputLabel>
              <Select
                labelId="mesCorrecao"
                id="mesCorrecao"
                name="mesCorrecao"
                onChange={handleChange}
              >
                <MenuItem value='JANEIRO'>JANEIRO</MenuItem>
                <MenuItem value='FEVEREIRO'>FEVEREIRO</MenuItem>
                <MenuItem value='MARÇO'>MARÇO</MenuItem>
                <MenuItem value='ABRIL'>ABRIL</MenuItem>
                <MenuItem value='MAIO'>MAIO</MenuItem>
                <MenuItem value='JUNHO'>JUNHO</MenuItem>
                <MenuItem value='JULHO'>JULHO</MenuItem>
                <MenuItem value='AGOSTO'>AGOSTO</MenuItem>
                <MenuItem value='SETEMBRO'>SETEMBRO</MenuItem>
                <MenuItem value='OUTUBRO'>OUTUBRO</MenuItem>
                <MenuItem value='NOVEMBRO'>NOVEMBRO</MenuItem>
                <MenuItem value='DEZEMBRO'>DEZEMBRO</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={4}>
            <FormControl fullWidth variant="outlined" className={classes.formControl}>
              <InputLabel shrink id="periodoContrato">Periodo do contrato</InputLabel>
              <Select
                fullWidth
                defaultValue='12'
                labelId="periodoContrato"
                name="periodoContrato"
                onChange={handleChange}
              >
                <MenuItem fullWidth value='12'>12 meses</MenuItem>
                <MenuItem value='18'>18 meses</MenuItem>
                <MenuItem value='24'>24 meses</MenuItem>
              </Select>
            </FormControl>
          </Grid>


          <Grid item xs={12}>
            <TextField multiline rows={10} rowsMax={10} fullWidth onChange={handleChange} name='plano' label="Plano Contratado" variant="outlined" />
          </Grid>

          <Grid item xs={12}>
              <TextField fullWidth onChange={handleChange}
                name='formato' label="Formato treinamento"
                variant="outlined" select>
                <MenuItem value="EAD - Sympla">EAD - Sympla</MenuItem>
                <MenuItem value="Via Meet (videoConferencia)">Via Meet (videoConferencia)</MenuItem>
              </TextField>
        
          </Grid>

          <Grid item xs={2}>
            <FormControl fullWidth variant="outlined">
              <InputLabel htmlFor="valorImplantacao">Valor implantação</InputLabel>
              <OutlinedInput
                id="valorImplantacao"
                name="valorImplantacao"
                type="number"
                onChange={handleChange}
                startAdornment={<InputAdornment position="start">R$</InputAdornment>}
                labelWidth={130}
              />
            </FormControl>
          </Grid>

          <Grid item xs={10}>
            <TextField fullWidth onChange={handleChange} value={campos.valorImplantacaoExtenso} name='valorImplantacaoExtenso' InputLabelProps={{ shrink: true }} label="Valor implantação extenso" variant="outlined" />
          </Grid>

          <Grid item xs={2}>
            <FormControl fullWidth variant="outlined">
              <InputLabel htmlFor="valorMensalidade">Valor mensalidade</InputLabel>
              <OutlinedInput
                id="valorMensalidade"
                name="valorMensalidade"
                type="number"
                onChange={handleChange}
                startAdornment={<InputAdornment position="start">R$</InputAdornment>}
                labelWidth={140}
              />
            </FormControl>
          </Grid>

          <Grid item xs={10}>
            <TextField fullWidth onChange={handleChange} value={campos ? campos.valorMensalidadeExtenso : ''} name='valorMensalidadeExtenso' InputLabelProps={{ shrink: true }} label="Valor mensalidade extenso" variant="outlined" />
          </Grid>

          <Grid item xs={2}>
            <TextField
              id="horaTreinamento"
              name="horaTreinamento"
              label="Horas treinamento"
              type="tel"
              onChange={handleChange}
              InputLabelProps={{
                shrink: true,
              }}
              variant="outlined"
            />
          </Grid>

          <Grid item xs={10}>
            <TextField fullWidth onChange={handleChange} value={campos ? campos.horaTreinamentoExtenso : ''} name='horaTreinamentoExtenso' InputLabelProps={{ shrink: true }} id="horaTreinamentoExtenso" label="horas treinamento extenso" variant="outlined" />
          </Grid>

          <Grid item xs={12}>
            <TextField multiline rows={10} rowsMax={10} fullWidth onChange={handleChange} name='resumoPagamento' label="Resumo pagamento" variant="outlined" />
          </Grid>
          <Grid item xs={12} align='center'>
            <Button fullWidth variant='outlined' color='secondary' onClick={onclickSave} >Salvar</Button>
          </Grid>
        </Grid>
      </Box>

    </Container >
  );
}

import React, { useState, useEffect } from 'react';
import {
  Container, Paper, Typography, TextField, Radio, RadioGroup, FormControlLabel, FormControl,
  FormLabel, Button, Select, MenuItem, InputLabel, Grid, Box, makeStyles, Snackbar, Slide
}
  from '@material-ui/core';
import Footer from '../components/Footer';
import EstadoSelect from './components/EstadoSelect';
import { InputCnpj, InputCpf, InputPhone, InputRg } from './components/MaskedInput';
import DoneIcon from '@material-ui/icons/Done'
import { useHistory, useLocation } from 'react-router-dom';
import { InputViaCep } from './components/InputViaCep';
import { ErrorMessageDialog, MuiDialog } from './components/DialogErrorMessage';

const CustomSnackbar = ({ open, onClose }) => {
  return (
    <Snackbar
      style={{
        fontFamily: 'Arial',
        backgroundColor: '#4CAF50',
        color: 'white',
        borderRadius: '10px',
        padding: '10px',
      }}
      open={open}
      autoHideDuration={2000}
      onClose={onClose}
      TransitionComponent={SlideTransition}
    >
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <DoneIcon style={{ marginRight: '8px' }} />
        Seus dados foram enviados com sucesso!
      </div>
    </Snackbar>
  );
};

const useStyles = makeStyles(( theme ) => ({
  mainTitle: {
    margin: '0',
    padding: '3px',
    textAlign: 'center',
    color: '#black',
    textShadow: '0px 1px 1px black'
  },

  stylePaper: {
    padding: '80px',
    marginTop: '20px',
    textShadow: '0px 0px 0px black',
    [theme.breakpoints.down('sm')]: {
      padding: '50px',
    },
  },

}));

const SlideTransition = React.forwardRef((props, ref) => {
  return <Slide ref={ref} {...props} direction="left" />;
});

const Cadastramento = () => {
  let history = useHistory();
  let location = useLocation();
  const classes = useStyles();

  const [emailSent, setEmailSent] = useState(false);
  const handleEmailSentClose = () => { setEmailSent(false); };
  const [emailError, setEmailError] = useState(false);

  const handleOpenErrorDialog = () => setEmailError(true);
  const handleCloseErrorDialog = () => setEmailError(false);

  const [loading, setLoading] = useState(false);
  const [loadingCepEmpresa, setLoadingCepEmpresa] = useState(false);
  const [loadingCepResponsavel, setLoadingCepResponsavel] = useState(false)

  const [formData, setFormData] = useState({
    razaoSocial: '', nomeFantasia: '', possuiCnpj: 'sim', endereco: '', bairro: '', empresaCep: '', numero: '', municipio: '', estado: '', telefoneEmpresa: '',
    emailEmpresa: '', responsavelNome: '', responsavelCep: '', responsavelEndereco: '', responsavelBairro: '', responsavelMunicipio: '', responsavelNumero: '', responsavelEstado: '', responsavelTelefone: '', responsavelEmail: '',
    inscricaoEstadual: '', cpf: '', rg: '', cnpj: '', contadorNome: '', regimeTributario: '', contadorEmail: '', contadorTelefone: '',
  });

  useEffect(() => {
    if (location.hash === "#email") {
      history.push("/");
    }
  }, []);

  const handleChange = (event) => {
    const { name, value } = event.target;

    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const formatDataForEmail = (formData) => {
    return `
      <p><strong>Dados da Empresa</strong></p>
  
      <br>Razão Social: ${formData.razaoSocial}</br>
      <br>Nome Fantasia: ${formData.nomeFantasia}</br>
      <br>CEP: ${formData.empresaCep}</br>
      <br>Endereço: ${formData.endereco}</br>
      <br>Bairro: ${formData.bairro}</br>
      <br>Número: ${formData.responsavelNumero}</br>
      <br>Município: ${formData.municipio}</br>
      <br>Estado: ${formData.estado}</br>
      <br>Telefone: ${formData.telefoneEmpresa}</br>
      <br>E-mail: ${formData.emailEmpresa}</br>
      <br>CNPJ: ${formData.cnpj}</br>
      <br>Inscrição Estadual (IE): ${formData.inscricaoEstadual}</br>
     
      <p><strong>Dados do Responsável</strong></p>
      <br>Nome: ${formData.responsavelNome}</br>
      <br>Cep: ${formData.responsavelCep}</br>
      <br>Endereço: ${formData.responsavelEndereco}</br>
      <br>Bairro: ${formData.responsavelBairro}</br>
      <br>Número: ${formData.responsavelNumero}</br>
      <br>Município: ${formData.responsavelMunicipio}</br>
      <br>Estado: ${formData.responsavelEstado}</br>
      <br>Telefone: ${formData.responsavelTelefone}</br>
      <br>E-mail: ${formData.responsavelEmail}</br>
      <br>CPF: ${formData.cpf}</br>
      <br>RG: ${formData.rg}</br>
  
      <p><strong>Dados do Contador</strong></p>
      <br>Nome: ${formData.contadorNome}</br>
      <br>Regime Tributário da Empresa: ${formData.regimeTributario}</br>
      <br>E-mail do Contador: ${formData.contadorEmail}</br>
      <br>Telefone do Contador: ${formData.contadorTelefone}</br>
    `;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    setLoading(true) // ativa o estado de carregamento

    const formattedData = formatDataForEmail(formData);

    const subject = `Nova ficha de cadastro - ${formData.nomeFantasia}`;

    const emailData = {
      from: 'noreply@parseint.com.br',
      to: 'fichacadastro@parseint.com.br',
      subject: subject,
      content: formattedData,
	  password: 'senhateste'
    };

    try {
      const response = await fetch('https://faas-nyc1-2ef2e6cc.doserverless.co/api/v1/web/fn-a56f6ac8-173c-47fb-ac04-ef39b056c04a/sample/emails', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(emailData),
      });

      if (response.ok) {
        window.location.hash = "email"
        setEmailSent(true);
        setTimeout(() => {
          setLoading(false) // desativa o estado de carregamento após o envio
          window.location.href = "https://sischef.com/obrigado/";
        }, 2000);
      } else {
        console.error('Erro ao enviar os dados:', response.status, response.statusText);
        handleOpenErrorDialog();
        setLoading(false)
      }

    } catch (error) {
      console.error('Erro ao enviar os dados', error);
      handleOpenErrorDialog();
      setLoading(false)
    }
  }

  function handleSuccess(key, data) {
    if (key === 'empresaCep') {
      setFormData((prevData) => ({
        ...prevData,
        'estado': data.uf || '',
        'bairro': data.bairro || '',
        'endereco': data.logradouro || '',
        'municipio': data.localidade || '',
      }));
      return
    }

    setFormData((prevData) => ({
      ...prevData,
      'responsavelEstado': data.uf || '',
      'responsavelBairro': data.bairro || '',
      'responsavelEndereco': data.logradouro || '',
      'responsavelMunicipio': data.localidade || '',
    }));
  }

  function handleLoading(key, value) {
    if (key === 'empresaCep') {
      setLoadingCepEmpresa(value)
    } else {
      setLoadingCepResponsavel(value)
    }
  }

  return (

    <Container maxWidth="md">

      <Box position="absolute" left={0} top={0} zIndex={-1} height="10px" width="100%" as="header" bgcolor="#FF4444"></Box>

      <form onSubmit={handleSubmit}>
        <Box display="flex" justifyContent="center" margin="20px">
          <img src='/images/logo-sischef-256.png' alt="logo sischef" width="110" height="110" />
        </Box>

        <Typography className={classes.mainTitle}
          variant="h4">Sischef - Cadastramento</Typography>

        <Paper elevation={8} className={classes.stylePaper}>
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <Typography style={{ fontWeight: 'bold', textAlign: 'center' }}
                variant="h5">Dados da Empresa</Typography>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField fullWidth onChange={handleChange}
                label="Razão Social"
                name="razaoSocial" required
                value={formData.razaoSocial}
              />
              </Grid>
            <Grid item xs={12}>
              <TextField
                label="Nome Fantasia"
                name="nomeFantasia"
                value={formData.nomeFantasia}
                onChange={handleChange}
                fullWidth
              /></Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <InputViaCep
                name="empresaCep"
                value={formData.empresaCep}
                onChange={handleChange}
                onSuccess={(data) => handleSuccess('empresaCep', data)}
                onLoading={(value) => handleLoading('empresaCep', value)}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label="Endereço"
                name="endereco"
                value={loadingCepEmpresa ? 'Carregando...' : formData.endereco}
                disabled={loadingCepEmpresa}
                onChange={handleChange}
                fullWidth
              />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <TextField
                label="Bairro"
                name="bairro"
                value={loadingCepEmpresa ? 'Carregando...' : formData.bairro}
                disabled={loadingCepEmpresa}
                onChange={handleChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label="Número"
                name="numero"
                value={formData.numero}
                onChange={handleChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label="Município"
                name="municipio"
                value={loadingCepEmpresa ? 'Carregando...' : formData.municipio}
                disabled={loadingCepEmpresa}
                onChange={handleChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <EstadoSelect
                value={formData.estado}
                loading={loadingCepEmpresa}
                disabled={loadingCepEmpresa}
                name='estado'
                onChange={handleChange}>
              </EstadoSelect>
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label="Telefone"
                name="telefoneEmpresa" required
                value={formData.telefoneEmpresa}
                onChange={handleChange}
                InputProps={{ inputComponent: InputPhone }}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label="E-mail"
                name="emailEmpresa" required
                value={formData.emailEmpresa}
                onChange={handleChange}
                fullWidth
              />
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <FormControl component="fieldset">
              <FormLabel style={{ fontWeight: 'bold', color: 'black', padding: '20px 0em 0em', lineHeight: '4em' }} component="legend" >Sua empresa já possui CNPJ?</FormLabel>
              <RadioGroup
                name="possuiCnpj"
                value={formData.possuiCnpj}
                onChange={handleChange}
                row>
                <FormControlLabel value="sim" control={<Radio />} label="Sim" />
                <FormControlLabel value="nao" control={<Radio />} label="Não" />
              </RadioGroup>
            </FormControl>
          </Grid>
          {formData.possuiCnpj === 'sim' ?
            <Grid item xs={12}>
              <TextField
                label="CNPJ"
                name="cnpj" required
                value={formData.cnpj}
                onChange={handleChange}
                InputProps={{ inputComponent: InputCnpj }}
                fullWidth
              />
              <Grid item xs={12}>
                <TextField
                  label="Inscrição Estadual (IE)"
                  name="inscricaoEstadual"
                  value={formData.inscricaoEstadual}
                  onChange={handleChange}
                  fullWidth
                />
              </Grid>
            </Grid>
            : null}

        </Paper>
        <Paper elevation={8} className={classes.stylePaper}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography style={{ fontWeight: 'bold', textAlign: 'center' }}
                variant="h5">Dados do Responsável</Typography>
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Nome"
                name="responsavelNome" required
                value={formData.responsavelNome}
                onChange={handleChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <InputViaCep
                name="responsavelCep"
                value={formData.responsavelCep}
                onChange={handleChange}
                onSuccess={(data) => handleSuccess('responsavelCep', data)}
                onLoading={(value) => handleLoading('responsavelCep', value)}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label="Endereço"
                name="responsavelEndereco"
                value={loadingCepResponsavel ? 'Carregando...' : formData.responsavelEndereco}
                disabled={loadingCepResponsavel}
                onChange={handleChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label="Bairro"
                name="responsavelBairro"
                value={loadingCepResponsavel ? 'Carregando...' : formData.responsavelBairro}
                disabled={loadingCepResponsavel}
                onChange={handleChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label="Número"
                name="responsavelNumero"
                value={formData.responsavelNumero}
                onChange={handleChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label="Município"
                name="responsavelMunicipio"
                value={loadingCepResponsavel ? 'Carregando...' : formData.responsavelMunicipio}
                disabled={loadingCepResponsavel}
                onChange={handleChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl fullWidth>
                <EstadoSelect
                  value={formData.responsavelEstado}
                  disabled={loadingCepResponsavel}
                  loading={loadingCepResponsavel}
                  name='responsavelEstado'
                  onChange={handleChange}>
                </EstadoSelect>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label="Telefone"
                name="responsavelTelefone" required
                value={formData.responsavelTelefone}
                InputProps={{ inputComponent: InputPhone }}
                onChange={handleChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label="E-mail"
                name="responsavelEmail" required
                value={formData.responsavelEmail}
                onChange={handleChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label="CPF"
                name="cpf" required
                value={formData.cpf}
                InputProps={{ inputComponent: InputCpf }}
                onChange={handleChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label="RG"
                name="rg"
                value={formData.rg}
                InputProps={{ inputComponent: InputRg }}
                onChange={handleChange}
                fullWidth
              />
            </Grid>
          </Grid>
        </Paper>
        <Paper elevation={8} className={classes.stylePaper}>
          <Grid container spacing={2}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography style={{ fontWeight: 'bold', textAlign: 'center' }} variant="h5">
                  Dados do Contador (Emissão Fiscal)
                </Typography>
                <Typography style={{ fontWeight: 'bold', textAlign: 'center', color: '#FF4444' }} variant="subtitle1">
                  *Preencha estes campos somente se for realizar emissão fiscal </Typography>
              </Grid>
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label="Nome"
                name="contadorNome"
                value={formData.contadorNome}
                onChange={handleChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl fullWidth>
                <InputLabel>Regime Tributário</InputLabel>
                <Select
                  name="regimeTributario"
                  value={formData.regimeTributario}
                  onChange={handleChange}
                >
                  <MenuItem value="Simples Nacional">Simples Nacional</MenuItem>
                  <MenuItem value="Lucro Real">Lucro Real</MenuItem>
                  <MenuItem value="Não Sei">Não sei</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label="E-mail do Contador"
                name="contadorEmail"
                value={formData.contadorEmail}
                onChange={handleChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label="Telefone do Contador"
                name="contadorTelefone"
                value={formData.contadorTelefone}
                InputProps={{ inputComponent: InputPhone }}
                onChange={handleChange}
                fullWidth
              />
            </Grid>
          </Grid>
          <Grid item xs={12} style={{ display: 'flex', justifyContent: 'end', marginTop: '35px' }}>
            <Button type='submit' variant="contained" style={{ backgroundColor: loading ? 'grey' : "#FF4444", color: 'white' }}
              disabled={loading}
              onSubmit={handleSubmit}>
              {loading ? 'Enviando...' : 'Enviar'}
            </Button>
          </Grid>
        </Paper>
        <Button aria-label="add" style={{ position: 'fixed', zIndex: 10, bottom: '8px', right: '30px', color: "#FF2A2A" }}>
          <MuiDialog />
        </Button>
      </form>
      <Footer />

      <CustomSnackbar open={emailSent} onClose={handleEmailSentClose} style={{ textDecoration: 'none' }} />
      <ErrorMessageDialog open={emailError} onClose={handleCloseErrorDialog} />

    </Container>
  );
};

export default Cadastramento;

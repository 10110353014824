import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import { valoresPlanosSischef } from './planos/valoresPlanosSischef'
import { valoresPlanosQrpedir } from './planos/valoresPlanosQrpedir'
import Paper from '@material-ui/core/Paper';
import { Avatar, Checkbox, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, Slide } from '@material-ui/core';
import List from '@material-ui/core/List';
import ListItemText from '@material-ui/core/ListItemText';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import Footer from '../components/Footer';
import DoneIcon from '@material-ui/icons/Done';
import CloseIcon from '@material-ui/icons/Close';
import { useParams } from 'react-router-dom';
import { TrainingPackageDialog } from './TrainingPackageDialog';

const useStyles = makeStyles((theme) => ({
  '@global': {
    ul: {
      margin: 0,
      padding: 0,
      listStyle: 'none',
    },
  },
  appBar: {
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  toolbar: {
    flexWrap: 'wrap',
  },
  toolbarTitle: {
    flexGrow: 1,
  },
  link: {
    margin: theme.spacing(1, 1.5),
  },
  heroContent: {
    padding: theme.spacing(8, 0, 6),
  },
  cardHeader: {
  },
  cardPricing: {
    display: 'flex',
    alignItems: 'baseline',
    marginBottom: theme.spacing(2),
  },
  footer: {
    borderTop: `1px solid ${theme.palette.divider}`,
    marginTop: theme.spacing(8),
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    [theme.breakpoints.up('sm')]: {
      paddingTop: theme.spacing(6),
      paddingBottom: theme.spacing(6),
    },
  },
  grow: {
    flexGrow: 1,
  },

  logo: {
    width: theme.spacing(10),
    height: theme.spacing(10),
  },
}));

export const formatMonetario = (value) => {
  if (value || value === 0) {
    let valueFloat = parseFloat(value)
    return valueFloat.toFixed(2).replace(".", ",")
  }
  return ""
}

const formatHoras = (horas) => {
  if (horas == 1) {
    return `${horas} hora`
  } else if (horas > 1) {
    return `${horas} horas`
  }
  return ""
}

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function AlertDialog({ open, setOpen, contextText }) {


  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Dialog
        fullWidth
        maxWidth="md"
        TransitionComponent={Transition}
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Resumo do plano escolhido</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">

            {contextText.map(line => (
              <React.Fragment key={line}>{line} <br /></React.Fragment>
            ))}

          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary" autoFocus endIcon={<CloseIcon />}>
            Fechar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default function Calculadora() {
  const params = useParams();
  const classes = useStyles();

  const [planos, setPlanos] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [resumoPlanoEscolhido, setResumoPlanoEscolhido] = useState([]);
  const [periodo, setPeriodo] = useState(12);
  const [percentualDesconto, setPercentualDesconto] = useState(0);

  useEffect(() => {
    const tipo = params.tipo

    if ("sischef" === tipo) {
      setPlanos(valoresPlanosSischef)
    } else if ("qrpedir" === tipo) {
      setPlanos(valoresPlanosQrpedir)
    }
  }, [])

  useEffect(() => {

    if (periodo === 18) {
      setPercentualDesconto(10)
    } else if (periodo === 24) {
      setPercentualDesconto(15)
    } else {
      setPercentualDesconto(0)
    }

  }, [periodo])

  const handleCheckModulo = (element) => {
    let checked = !element.selecionado;
    element.selecionado = checked;

    console.log('handleCheckModulo -> element -> ', checked)


    element.modulosInternos.map(moduloInterno => {

      console.log('handleCheckModulo -> element -> modulosInternos -> ', moduloInterno, checked)

      if (!checked) {
        if (moduloInterno.tipo == 'NUMERO') {
          moduloInterno.quantidade = 0;
        } else {
          moduloInterno.selecionado = false
        }
      } else {
        if (moduloInterno.tipo == 'NUMERO') {
          moduloInterno.quantidade = moduloInterno.defaultQuantidade;
        } else {
          moduloInterno.selecionado = moduloInterno.selecionadoDefault;
        }
      }
    })

    // else {
    //   element.modulosInternos.map(moduloInterno => {

    //     if (moduloInterno.tipo == 'NUMERO') {
    //       moduloInterno.quantidade = 0;
    //     } else {
    //       moduloInterno.selecionado = false;
    //     }
    //   })
    // }

    setPlanos({ ...planos })
  }

  const handleCalculateTotal = () => {
    let totalAtivacao = 0.0;
    let totalMensalidade = 0.0;
    let totalHoras = 0;
    let totalFrete = 0;
    let textoExtenso = []

    planos.grupos.map(grupo => {

      grupo.modulos.map(modulo => {

        if (modulo.tipo === "NUMERO") {

          if (modulo.quantidade > 0) {
            textoExtenso.push(`${modulo.quantidade}x ${modulo.descricao}`)
            totalAtivacao += (modulo.quantidade * modulo.valorAtivacao);
            totalMensalidade += (modulo.quantidade * modulo.valorMensalidade);
            totalHoras += modulo.horas ? (modulo.horas * modulo.quantidade) : 0;
          }

        } else {

          if (modulo.selecionado) {
            textoExtenso.push(modulo.descricao)
            totalAtivacao += modulo.valorAtivacao;
            totalMensalidade += modulo.valorMensalidade;
            totalHoras += modulo.horas ? modulo.horas : 0;
          }

        }
        if (modulo.selecionado) {
          modulo.modulosInternos.map(moduloInterno => {

            if (moduloInterno.tipo === "NUMERO") {

              if (moduloInterno.quantidade > 0) {
                textoExtenso.push(`+ ${moduloInterno.quantidade}x ${moduloInterno.descricao}`)
                totalAtivacao += (moduloInterno.quantidade * moduloInterno.valorAtivacao);
                totalMensalidade += (moduloInterno.quantidade * moduloInterno.valorMensalidade);
                totalHoras += moduloInterno.horas ? (moduloInterno.horas * moduloInterno.quantidade) : 0;
                totalFrete += (moduloInterno.valorFrete || 0)
              }

            } else {

              if (moduloInterno.selecionado) {
                textoExtenso.push("+ " + moduloInterno.descricao)
                totalAtivacao += moduloInterno.valorAtivacao;
                totalMensalidade += moduloInterno.valorMensalidade;
                totalHoras += moduloInterno.horas ? moduloInterno.horas : 0;
              }

            }
          })
        }
      })
    })

    textoExtenso.push("")
    textoExtenso.push("")

    if ("sischef" === params.tipo) {
      textoExtenso.push(`Período do contrato ${periodo} meses`)
    }

    textoExtenso.push(`Total de horas para implantação e treinamento: ${totalHoras} horas`)
    textoExtenso.push(`Valor da implantação e treinamento: R$ ${formatMonetario(totalAtivacao)} `)


    const economia = formatMonetario((totalMensalidade - totalMensalidade * (1 - (percentualDesconto / 100))))

    if (periodo > 12) {
      textoExtenso.push(`Valor da mensalidade de R$ ${formatMonetario(totalMensalidade)} por R$ ${formatMonetario(totalMensalidade * (1 - (percentualDesconto / 100)))}`)
      textoExtenso.push(`Economia de R$ ${economia} por mês`)
    } else {
      textoExtenso.push(`Valor da mensalidade: R$ ${formatMonetario(totalMensalidade)}`)
    }

    if ("qrpedir" === params.tipo) {
      textoExtenso.push(`Frete Cavaletes${formatMonetario(totalFrete)}`)
    }

    setResumoPlanoEscolhido(textoExtenso)

    setDialogOpen(true)

  }

  const handleCheckModuloInterno = (element, fatherElement, event) => {
    let checked = !element.selecionado;
    element.selecionado = checked;

    if (checked) {
      if (fatherElement) {
        fatherElement.selecionado = true;
      }
    }

    setPlanos({ ...planos })

    return false;
  }

  const handleRemoveModuloInterno = (moduloInterno, fatherElement) => {
    const defaultQuantidade = moduloInterno.defaultQuantidade || 0;

    if (moduloInterno.quantidade > 0 && moduloInterno.quantidade > defaultQuantidade ) {
      moduloInterno.quantidade += -1;
    }

    setPlanos({ ...planos })
  }

  const handleAddModuloInterno = (moduloInterno, fatherElement) => {
    moduloInterno.quantidade += 1;

    if (moduloInterno.quantidade > 0) {
      if (fatherElement) {
        fatherElement.selecionado = true;
      }
    }

    setPlanos({ ...planos })
  }

  return (
    <React.Fragment>
      <CssBaseline />

      {planos === null ? (
        <Container maxWidth="sm" component="main" className={classes.heroContent}>

          <Typography component="h1" variant="h4" align="center" color="textPrimary" gutterBottom>
            <CircularProgress />  Carregando...
          </Typography>
        </Container>
      ) : (


        <React.Fragment>
          <Container maxWidth="sm" component="main" className={classes.heroContent}>
            <Box display="flex" alignItems="center" justifyContent="center">
              <Avatar alt="Logo Sischef"

                src={process.env.PUBLIC_URL + planos.logo} className={classes.logo} />
            </Box>

            <Typography component="h1" variant="h2" align="center" color="textPrimary" gutterBottom>
              {planos.tituloPrincipal}
            </Typography>

            <Typography variant="h5" align="center" color="textSecondary" component="p">
              {planos.tituloSecundario}
            </Typography>
          </Container>
          {/* End hero unit */}

          <Container maxWidth="lg" component="main">

            {
              planos.grupos.map(grupo => (
                <Box m={2} key={grupo.id}>


                  {/* <Paper elevation={1} key={grupo.id} > */}

                  <Box p={2}>
                    <Typography variant="h6" align="center" color="textSecondary" component="p">
                      {grupo.descricao}
                    </Typography>
                  </Box>
                  <Box p={2} display="flex" >


                    <Grid container spacing={5} >
                      {
                        grupo.modulos.filter(item => item.id !== 'Outros').map(modulo => (
                          <Grid item key={modulo.id} xs={12}
                            sm={6}
                            md={4}>
                            <Card elevation={0} variant="outlined">
                              <CardHeader

                                title={modulo.descricao}
                                subheader={
                                  <Box>
                                    {modulo.valorAtivacao ?
                                      <Box>
                                        Ativação {formatMonetario(modulo.valorAtivacao)} {modulo.horas > 0 ? ` (${formatHoras(modulo.horas)})` : null}
                                      </Box> : null}
                                    {modulo.valorMensalidade ? <Box> + {formatMonetario(modulo.valorMensalidade)}/mês</Box> : null}
                                  </Box>
                                }
                                className={classes.cardHeader}
                                action={
                                  <Checkbox
                                    disabled={modulo.bloqueado}
                                    onChange={event => handleCheckModulo(modulo)}
                                    inputProps={{ 'aria-label': 'primary checkbox' }}
                                    checked={modulo.selecionado}
                                  />
                                }
                              />
                              <CardContent style={{ padding: 0 }}>

                                {modulo.descricaoComplementar ? (
                                  <Box px={2}>
                                    <Typography variant="subtitle1" gutterBottom align="center">
                                      {modulo.descricaoComplementar}
                                    </Typography>
                                  </Box>
                                ) : null}

                                <List dense >
                                  {modulo.modulosInternos.map((moduloInterno) => (
                                    <React.Fragment key={moduloInterno.id}>
                                      <Divider />
                                      <ListItem key={moduloInterno.id} button

                                        disabled={moduloInterno.bloqueado}
                                        onClick={event => handleCheckModuloInterno(moduloInterno, modulo)}>
                                        <ListItemIcon>
                                          {moduloInterno.tipo === "NUMERO" ? (
                                            <Typography variant="h5" >
                                              {moduloInterno.quantidade > 0 ? moduloInterno.quantidade : '--'}
                                            </Typography>

                                          ) : (
                                            <Checkbox
                                              edge="start"
                                              disableRipple
                                              checked={moduloInterno.selecionado}
                                              inputProps={{ 'aria-labelledby': moduloInterno.id }}
                                            />
                                          )}

                                        </ListItemIcon>

                                        <ListItemText id={moduloInterno.id}
                                          primaryTypographyProps={{
                                            variant: "button"
                                          }}
                                          primary={
                                            <React.Fragment>
                                              {moduloInterno.descricao}
                                            </React.Fragment>
                                          }
                                          secondaryTypographyProps={{ component: "span" }}
                                          secondary={
                                            <Box>
                                              {moduloInterno.subTitulo ? <strong>{moduloInterno.subTitulo + " "}</strong> : null}
                                              {moduloInterno.valorAtivacao ?
                                                <Box>
                                                  Ativação {formatMonetario(moduloInterno.valorAtivacao)} {moduloInterno.horas > 0 ? `(${formatHoras(moduloInterno.horas)})` : null}
                                                </Box> : null
                                              }
                                              {moduloInterno.valorMensalidade ? <Box> + {formatMonetario(moduloInterno.valorMensalidade)}/mês</Box> : null}
                                            </Box>
                                          } />

                                        {moduloInterno.tipo === "NUMERO" ? (
                                          <ListItemSecondaryAction>
                                            <IconButton size="small" onClick={() => handleAddModuloInterno(moduloInterno, modulo)}><AddIcon /></IconButton> <br />
                                            <IconButton size="small" onClick={() => handleRemoveModuloInterno(moduloInterno, modulo)}><RemoveIcon /></IconButton>
                                          </ListItemSecondaryAction>
                                        ) : null}

                                      </ListItem>

                                    </React.Fragment>
                                  ))}

                                </List>

                              </CardContent>

                            </Card>
                          </Grid>
                        ))
                      }

                    </Grid>


                  </Box>
                  {/* </Paper> */}
                </Box>
              ))
            }

            <Box m={2} >

              <Paper elevation={1} >
                <Box p={2} display="flex">
                  <Grid container
                    spacing={2}
                    direction="row"
                    justifyContent='flex-end'
                    alignItems="center">

                    <Grid item >
                      <Button color="secondary" variant="contained" fullWidth
                        size="large" onClick={() => {
                          if (params.tipo === 'qrpedir') {
                            handleCalculateTotal()
                          }

                          setDialogOpen(true)
                        }}
                        endIcon={<DoneIcon />} >Calcular valor</Button>
                    </Grid>

                  </Grid>

                </Box>

              </Paper>

            </Box>

          </Container>

        </React.Fragment>

      )}


      <Footer />

      {params.tipo === 'qrpedir' ? (
        <AlertDialog
          open={dialogOpen}
          setOpen={setDialogOpen}
          contextText={resumoPlanoEscolhido} />
      ) : (
        <TrainingPackageDialog
          open={dialogOpen}
          tipo={params.tipo}
          planos={planos}
          onChangePlanos={(newPlanos) => setPlanos(newPlanos)}
          setOpen={setDialogOpen} />
      )}


    </React.Fragment>
  );
}
import MaskedInput from 'react-text-mask';
import React from 'react';

export function InputPhone(props) {
    const { inputRef, ...other } = props;

    return (
        <MaskedInput
            {...other}
            guide={false}
            ref={(ref) => {
                inputRef(ref ? ref.inputElement : null);
            }}
            mask={['(', /[0-9]/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
        />
    )
}

export function InputCep(props) {
    const { inputRef, ...other } = props;


    return (
        <MaskedInput
            {...other}
            guide={false}
            ref={(ref) => {
                inputRef(ref ? ref.inputElement : null);
            }}
            mask={[/[0-9]/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/]}
        />
    )
}

export function InputCpf(props) {
    const { inputRef, ...other } = props;


    return (
        <MaskedInput
            {...other}
            guide={false}
            ref={(ref) => {
                inputRef(ref ? ref.inputElement : null);
            }}
            mask={[/[0-9]/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/, /\d/]}

        />
    )
}

export function InputCnpj(props) {
    const { inputRef, ...other } = props;


    return (
        <MaskedInput
            {...other}
            guide={false}
            ref={(ref) => {
                inputRef(ref ? ref.inputElement : null);
            }}
            mask={[/[0-9]/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/,]}

        />
    )
}

export function InputRg(props) {
    const { inputRef, ...other } = props;


    return (
        <MaskedInput
            {...other}
            guide={false}
            ref={(ref) => {
                inputRef(ref ? ref.inputElement : null);
            }}
            mask={[/[0-9]/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/,]}

        />
    )
}

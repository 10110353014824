import React from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import Calculadora from "./calculadora"
import Contrato from "./contrato"
import Main from "./main"
import CadastroForm from "./cadastramento"


const Routes = () => (
    <BrowserRouter>
      <Switch>
  
        <Route exact path="/" component={Main} />
        <Route exact path="/contrato" component={Contrato} />
        <Route exact path="/calculadora/:tipo" component={Calculadora} />
        <Route exact path="/cadastramento/" component={CadastroForm} />
        
      </Switch>
    </BrowserRouter>
  );

  export default Routes;
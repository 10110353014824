import React from 'react';
import { Button, Dialog, DialogContent, DialogContentText, DialogActions } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Help';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import CallIcon from '@material-ui/icons/Call';
import MailOutlineIcon from '@material-ui/icons/MailOutline';

export const MuiDialog = () => {

    return (
        <a href="https://sischef.space/" title="Suporte do Sischef" target="_blank" rel="noopener noreferrer" style={{textDecoration:'none'}}>
            <span style={{ display: 'flex', alignItems:'center', color:'red'}}>
            <AddIcon style={{ color: 'red', marginRight:'5px'}}/>
            Ajuda
            </span>
        </a>

    );
};

export const ErrorMessageDialog = ({ open, onClose }) => {
    return (
        <Dialog
            open={open}
            onClose={onClose}
            maxWidth='xs'
            aria-labelledby='error-dialog-title'
            aria-describedby='error-dialog-description'
            PaperProps={{ style: { borderRadius: '15px', padding: '20px', margin: 'auto' } }}
            style={{ textAlign: 'center', padding: '0' }}
        >
            <hr style={{ width: '80%', borderColor: 'red', marginTop: '10px' }} />
            <DialogContentText style={{ color: 'red', textAlign: 'center', fontSize: '24px', fontWeight: 'bolder', marginBottom: '10px' }} id='error-dialog-title'>ERRO AO ENVIAR DADOS</DialogContentText>

            <DialogContent style={{ textAlign: 'left', marginBottom: '20px' }}>
                <DialogContentText style={{ color: 'black', marginBottom: '20px', fontWeight: 'bold' }}>Ocorreu um erro ao tentar enviar os dados. Favor entrar em contato com nosso suporte (horário comercial):</DialogContentText>
                <DialogContentText style={{ color: 'black', marginBottom: '5px', fontWeight: 'bold' }}><CallIcon style={{ fontSize: 'medium', color: 'black', marginRight: '5px' }} />Fone: (46) 3199-0986</DialogContentText>
                <DialogContentText style={{ color: 'black', marginBottom: '5px', fontWeight: 'bold' }}><WhatsAppIcon style={{ fontSize: 'medium', color: 'black', marginRight: '5px' }} /> WhatsApp: <a href="https://wa.me/5546991390992" target="_blank" rel="noopener noreferrer" style={{ color: "black" }}>(46) 9.9139-0992</a> </DialogContentText>
                <DialogContentText style={{ color: 'black', marginBottom: '5px', fontWeight: 'bold' }}><MailOutlineIcon style={{ fontSize: 'small', color: 'black', marginRight: '5px' }} />E-mail: <a href="mailto:comercial@parseint.com.br" style={{ color: "black" }}>comercial@parseint.com.br</a></DialogContentText>
            </DialogContent>

            <DialogActions style={{ position: 'absolute', bottom: '10px', right: '10px' }}>
                <Button style={{ fontWeight: 600, color: 'red', width: '100px', fontSize: '15px' }} onClick={onClose}>Fechar</Button>
            </DialogActions>
        </Dialog>
    );
};



export const valoresPlanosQrpedir = {

    logo: "/images/logo-qrpedir-256.png",
    tituloPrincipal: "qrpedir",
    tituloSecundario: "Monte o plano perfeito para você",

    descricao: "Calculadora de planos Qrpedir",
    grupos: [
        {
            id: "GRUPO_PRINCIPAL",
            descricao: "Planos disponíveis",

            modulos: [
                {
                    id: "PlanosAnuais",
                    descricao: "Plano Anual",
                    descricaoComplementar: "Onboarding incluso",
                    valorAtivacao: 0.0,
                    valorMensalidade: 0.0,
                    selecionado: false,
                    horas: 0,
                    bloqueado: false,
                    periodicidade: 'ANUAL',
                    modulosInternos:[
                        {
                            id: "Qrpedir Delivery",
                            descricao: "Qrpedir Delivery",
                            valorAtivacao: 300.0,
                            valorMensalidade: 99.90,
                            selecionado: false,
                        },
                        {
                            id: "Qrpedir na Mesa",
                            descricao: "Qrpedir na Mesa",
                            valorAtivacao: 400.0,
                            valorMensalidade: 99.90,
                            selecionado: false,
                        },

                    ]
                },
                {
                    id: "PlanosMensais",
                    descricao: "Plano Mensal",
                    descricaoComplementar: "Onboarding incluso",
                    valorAtivacao: 0.0,
                    valorMensalidade: 0.0,
                    selecionado: false,
                    horas: 0,
                    bloqueado: false,
                    periodicidade: 'MENSAL',
                    modulosInternos:[
                        {
                            id: "Qrpedir Delivery",
                            descricao: "Qrpedir Delivery",
                            valorAtivacao: 400.0,
                            valorMensalidade: 119.9,
                            selecionado: false,
                        },
                        {
                            id: "Qrpedir na Mesa",
                            descricao: "Qrpedir na Mesa",
                            valorAtivacao: 500.0,
                            valorMensalidade: 119.9,
                            selecionado: false,
                        },
                    ]
                },

                {
                    id: "Pacotes Adicionais",
                    descricao: "Pacotes Adicionais",
                    valorAtivacao: 0.0,
                    valorMensalidade: 0.0,
                    selecionado: false,
                    horas: 0,
                    bloqueado: false,
                    modulosInternos:[
                        {
                            id: "Configuração de impressoras",
                            descricao: "Configuração de impressoras",
                            valorAtivacao: 149.90,
                            valorMensalidade: 0.0,
                            selecionado: false,
                        },
                        // {
                        //     id: "Consultoria de Marketing",
                        //     descricao: "1H Consultoria de Marketing",
                        //     valorAtivacao: 249.90,
                        //     valorMensalidade: 0.0,
                        //     selecionado: false,
                        // },
                        {
                            id: "Cadastro de produto (a cada 100)",
                            descricao: "Cadastro de produto (a cada 100)",
                            valorAtivacao: 149.90,
                            valorMensalidade: 0.0,
                            quantidade: 0,
                            tipo: "NUMERO",
                        },
                        
                        {
                            id: "CavaleteMesa",
                            descricao: "Cavalete mesa com qrcode",
                            valorAtivacao: 25.00,
                            valorMensalidade: 0.0,
                            valorFrete: 100.00,
                            quantidade: 0,
                            tipo: "NUMERO",
                        },
                        
                    ]
                },
                 
                 
                
            ]
        }, 
         
    ]

}